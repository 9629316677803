<template>
    <div class="doctor-page doctor-introduction">
        <div class="banner">
            <img
                v-if="isCaseEditable"
                src="/doctor-unsend.png"
                alt="pic"
                width="100%"
            />
            <img v-else src="/doctor-send.png" alt="pic" width="100%" />
        </div>
        <div class="state">
            <button-wrapper
                type="navigation"
                color="normal"
                @click="$router.push({ name: 'Doctor_Form' })"
            >
                {{ isCaseEditable ? "編 輯" : "瀏 覽 案 例" }}
            </button-wrapper>
            <div class="hint">
                {{ isCaseEditable ? "案例尚未送出" : "案例已送出" }}
            </div>
        </div>
        <div class="policy-box">
            <div
                class="item reveal-information"
                @click="onOpenRevealInformationPopup"
            >
                羅氏揭露訊息
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
    computed: {
        ...mapState({
            isCaseEditable: (state) => state.doctor.state === 'doctor-save'
                || state.doctor.state === 'draft'
                || state.doctor.state === 'reject',
        }),
    },
    mounted() {
        this.updateDisclaimerPop(true);
    },
    methods: {
        ...mapMutations({
            updateDisclaimerPop: 'popup/updateDisclaimerPop',
            updateRevealInformationPopupState: 'popup/updateRevealInformationPopupState',
        }),
        onOpenRevealInformationPopup() {
            this.updateRevealInformationPopupState(true);
        },
    },
};
</script>

<style lang="scss" scoped>
.doctor-introduction {
    .banner {
        max-width: 500px;
    }
    .state {
        margin-top: 50px;
        text-align: center;
    }
    .hint {
        margin-top: 20px;
    }
}
</style>
